<template>
    <div>
        <NavBar :isHome='false' />
        <div class="container-fluid recruit-banner">
            <div class="row">
                <p class="recruit-banner-title">{{recruitTextConfig.bannerTitle}}</p>
                <p class="recruit-banner-desc">{{recruitTextConfig.bannerDesc}}</p>
            </div>
        </div>
        <div class="sub-nav-container-recruit container-fluid">
            <div @click="[subNavSelected = index,$router.push(item.url)]" class="recruit-sub-nav-text" :class="subNavSelected === index?'recruit-sub-nav-text-active':''" v-for="(item, index) in subNavBarData" :key="index">
                {{item.text}}
                <div
                :class="subNavSelected === index?'recruit-sub-nav-border-active':''"
                class="recruit-sub-nav-border"></div>
            </div>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar'
import { recruitTextConfig } from "@/config/Recruit"
export default {
    components:{
        NavBar
    },
    data(){
        return {
            recruitTextConfig:recruitTextConfig,
            subNavSelected:0,
            subNavBarData:[
                {
                    index:0,
                    text:'採用方針',
                    name:'policy',
                    url:'/recruit',
                },
                // {
                //     index:1,
                //     text:'新卒採用',
                //     name:'graduated',
                //     url:'/recruit/graduated',
                // },
                {
                    index:2,
                    text:'中途採用',
                    name:'social',
                    url:'/recruit/social',
                }
            ]
        }
    },
    mounted() {
        this.subNavInit()
    },
    methods: {
        subNavInit(){
            this.subNavSelected = this.subNavBarData.find(k => k.name === this.$route.name).index
        }
    },
}
</script>

<style>
@media screen and (min-width:768px) and (max-width:1920px){
    .recruit-banner{
        background-image:url('../assets/images/recruit/banner7.png');
    }
    @media screen and (max-width:768px) {
        .recruit-banner{
            height: 125px !important;
            height: calc(100vw/1920*640) !important;
        }
    }
    .recruit-banner{
        margin-top: 20px;
        height: calc(100vw/1920*400);
        background-size: contain;
        background-repeat: no-repeat;
    }
    .recruit-banner div{
        width: calc(100vw/1920*554);
        text-align: left;
        position: absolute;
        top: calc(100vw/1920*192);
        left:calc(100vw/1920*360);
    }
    .recruit-banner-title{
        font-size:calc(100vw/1920*30);
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(255,255,255,1);
        line-height:60px;
        text-shadow:0px 2px 2px rgba(0,0,0,0.15);
    }
    .recruit-banner-desc{
        font-size:calc(100vw/1920*16);
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(255,255,255,1);
        line-height:32px;
        text-shadow:0px 1px 1px rgba(0,0,0,0.5);
    }
    .sub-nav-container-recruit{
        height: 80px;
        display: flex;
        padding:0 calc(100vw/1920*360);
        border-bottom: 1px solid #DDDDDD;
    }
    .recruit-sub-nav-text{
        cursor: pointer;
        font-size:calc(100vw/1920*24);
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        line-height:calc(100vw/1920*33);
        width:168px;
        line-height: 76px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        width: 100vw;

    }
    .recruit-sub-nav-border{
        width: 0;
        margin: 0 auto;
        border-bottom: 4px solid #FFF;
    }
    .recruit-sub-nav-text-active{
        color:rgba(2,98,255,1);
        transition: all 0.5s;
    }
    .recruit-sub-nav-border-active{
        width: 100px;
        transition: all 0.5s;
        border-bottom: 4px solid #0262FF;
    }
}
@media screen and (max-width:768px){
    .recruit-banner{
        background-image:url('../assets/images/recruit/banner7_mb.png');
        margin-top: 20px;
        height: 140px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .recruit-banner div{
        /* width: 100%; */
        text-align: left;
        position: absolute;
        top: 40px;
        left:60px;
    }
    .recruit-banner-title{
        position: relative;
        top: 60px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(255,255,255,1);
        line-height:30px;
        text-shadow:0px 2px 2px rgba(0,0,0,0.15);
    }
    .recruit-banner-desc{
        display: none;
        font-size:calc(100vw/1920*16);
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(255,255,255,1);
        line-height:32px;
        text-shadow:0px 1px 1px rgba(0,0,0,0.5);
    }
    .sub-nav-container-recruit{
        height: 80px;
        display: flex;
        border-bottom: 1px solid #DDDDDD;
    }
    .recruit-sub-nav-text{
        cursor: pointer;
        font-size:12px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        line-height:14px;
        width:168px;
        line-height: 76px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        width: 100vw;

    }
    .recruit-sub-nav-border{
        width: 0;
        margin: 0 auto;
        border-bottom: 4px solid #FFF;
    }
    .recruit-sub-nav-text-active{
        color:rgba(2,98,255,1);
        transition: all 0.5s;
    }
    .recruit-sub-nav-border-active{
        width: 100px;
        transition: all 0.5s;
        border-bottom: 4px solid #0262FF;
    }
}
@media screen and (min-width:1920px){
    .recruit-banner{
        background-image:url('../assets/images/recruit/banner7.png');
    }
    .recruit-banner{
        margin-top: 20px;
        height: 400px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .recruit-banner div{
        width: 554px;
        text-align: left;
        position: absolute;
        top: 192px;
        left:360px;
    }
    .recruit-banner-title{
        font-size:30px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(255,255,255,1);
        line-height:60px;
        text-shadow:0px 2px 2px rgba(0,0,0,0.15);
    }
    .recruit-banner-desc{
        font-size:16px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(255,255,255,1);
        line-height:32px;
        text-shadow:0px 1px 1px rgba(0,0,0,0.5);
    }
    .sub-nav-container-recruit{
        height: 80px;
        display: flex;
        padding:0 360px;
        border-bottom: 1px solid #DDDDDD;
    }
    .recruit-sub-nav-text{
        cursor: pointer;
        font-size:24px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        line-height:33px;
        width:168px;
        line-height: 76px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        width: 100vw;

    }
    .recruit-sub-nav-border{
        width: 0;
        margin: 0 auto;
        border-bottom: 4px solid #FFF;
    }
    .recruit-sub-nav-text-active{
        color:rgba(2,98,255,1);
        transition: all 0.5s;
    }
    .recruit-sub-nav-border-active{
        width: 100px;
        transition: all 0.5s;
        border-bottom: 4px solid #0262FF;
    }
}
</style>